import HttpClient from "@/http/httpClient";
import type { getWorkspaceSourcesResponseType } from "../workspaces/types";
import type {
  PostSourceFileRequestType,
  PostSourceFileResponseType,
  PostSourceURLSRequestType,
  PostSourceURLSResponseType,
  getSourcesResponseType,
  postSourcePDFType,
  PutSourceRequestType,
  PutSourceResponseType,
  SourceType,
} from "./types";

export async function getSources() {
  return await HttpClient.request().get<getWorkspaceSourcesResponseType>(
    "sources",
  );
}

export async function getSingleSource(id: string) {
  return await HttpClient.request().get<SourceType>(`sources/${id}`);
}

export async function postSourceFile({
  file,
  workspace_id,
}: PostSourceFileRequestType) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("workspace_id", workspace_id);
  return await HttpClient.request().post<PostSourceFileResponseType>(
    "sources/file",
    {
      body: formData,
    },
  );
}

export async function postSourceURLs(data: PostSourceURLSRequestType) {
  return await HttpClient.request().post<PostSourceURLSResponseType>(
    "sources/urls",
    {
      json: data,
    },
  );
}

export async function postSourcePDF(source: postSourcePDFType) {
  const http = HttpClient.request();
  const ky = http.ky();

  const { id, file_name, page_no, relevant_bboxes } = source;
  return await ky
    .post(`sources/${id}.pdf`, {
      prefixUrl: import.meta.env.VITE_APP_URL,
      json: {
        file_name: file_name,
        page_number: page_no,
        relevant_bboxes: relevant_bboxes,
      },
    })
    .blob();
}

export async function deleteSources(id: string) {
  return await HttpClient.request().delete<getSourcesResponseType>(
    `sources/${id}`,
  );
}

export async function postReferenceDocument(file: File) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return await HttpClient.request().post("sources/reference-document", {
    body: formData,
    headers: {},
  });
}

export async function putSource(source: PutSourceRequestType) {
  return await HttpClient.request().put<PutSourceResponseType>(
    `sources/${source.id}`,
    {
      json: source,
    },
  );
}
