import { AppFeatureEnum } from "./http/api/users/types";

export const INTEGRATION_POPUP_DELAY = 10_000; // 10 seconds

export const EXCEL_MIME = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const EXCEL_EXTENSIONS = [".xls", ".xlsx"];

export const ACCEPTED_FILE_EXTENSIONS = [".pdf", ".doc", ".docx"];
export const ACCEPTED_MIME = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
export const INPUT_FILE_ACCEPT = [
  ...ACCEPTED_FILE_EXTENSIONS,
  ...ACCEPTED_MIME,
].join(",");

export const ERRORS = {
  NO_WORKSPACE: "No workspace found",
};

export const SOURCE_CATEGORIES = [
  "General Knowledge",
  "Previous RFPs",
  "Marketing Materials",
  "Legal Documents",
  "Reports",
  "Financial Data",
  "News Articles",
  "Regulatory Updates",
  "Previous DDQs",
];

export const MARKET_NEWSFLASH_TEMPLATE_SLUG = "newsflash";
export const REGULATORY_UPDATES_TEMPLATE_SLUG = "reg_update";
export const RFP_TEMPLATE_SLUG = "rfp";

export const REGULAR_CHATBOT_SLUG = "regular";
export const A_TO_B_CHATBOT_SLUG = "a-to-b";

export const DB_COMPANY_ID = "09b6862b-7375-44dd-b954-871be5d15bbc";
export const KODEX_COMPANY_ID = "3363ae60-14a4-4e66-95f7-6757b92bf208";

export const DEFAULT_TYPING_SPEED = 10;

export const SELECTABLE_APP_FEATURES = [
  {
    label: "Reports",
    value: AppFeatureEnum.REPORTS,
  },
  {
    label: "Questionnaires",
    value: AppFeatureEnum.QUESTIONNAIRES,
  },
  {
    label: "Chatbots",
    value: AppFeatureEnum.CHATBOTS,
  },
  {
    label: "Regulatory Hub",
    value: AppFeatureEnum.REGULATORY_HUB,
  },
  {
    label: "Obligations & Controls",
    value: AppFeatureEnum.OBLIGATIONS,
  },
];

export const PAGE_HEADER_HEIGHT = "4rem";
export const CONVERSATION_HEIGHT = `calc(100dvh - ${PAGE_HEADER_HEIGHT} - 1.5rem)`; // TODO: Fix this magic number. It is due to padding

export const DEMO_COMPANY_IDS = [
  "97768310-a6a7-443b-8263-ba58692e971e", // Fake Big Bank
  "2b2100a6-8345-47fe-a5b5-e774b0d53c6c", // Fake Crypto Company
];
