import {
  createTheme,
  rem,
  type CSSVariablesResolver,
  type MantineThemeOverride,
} from "@mantine/core";

export const themeObject: MantineThemeOverride = {
  fontFamily: "DM Sans Variable, sans-serif;",
  defaultRadius: "sm",
  headings: {
    fontWeight: "500",
    sizes: {
      h1: { fontSize: rem(24), lineHeight: "1.4" },
      h2: { fontSize: rem(20), lineHeight: "1.2" },
    },
  },
  primaryColor: "lochmara",
  colors: {
    lochmara: [
      "#f2f8fd",
      "#e4effa",
      "#c2dff5",
      "#8cc4ed",
      "#4fa5e1",
      "#288acf",
      "#1b75bc",
      "#16588e",
      "#164b76",
      "#184062",
      "#102841",
    ],
    neutral: [
      "#f4f7fd",
      "#e9effb",
      "#dae6f5",
      "#cbd8e7",
      "#afbed0",
      "#92a3ba",
      "#7b8fa8",
      "#647c97",
      "#576d86",
      "#465a6f",
      "#384759",
      "#253241",
      "#1a2634",
    ],
    finn: [
      "#f8f1f8",
      "#ece1eb",
      "#dabed7",
      "#c79ac3",
      "#b77bb1",
      "#ae68a7",
      "#aa5ea2",
      "#954e8e",
      "#85447f",
      "#75396f",
    ],
    rose: [
      "#ffebf3",
      "#fad8e1",
      "#ecb0c0",
      "#df859e",
      "#d46180",
      "#ce496e",
      "#cb3c64",
      "#b42e54",
      "#a2264a",
      "#8f1a3f",
    ],
    indigo: [
      "#f1f1ff",
      "#e0e0f4",
      "#bfbedf",
      "#9b9acb",
      "#7d7bb9",
      "#6a68af",
      "#605eab",
      "#504e97",
      "#464588",
      "#3b3b7a",
    ],
    vanilla: [
      "#fbfce6",
      "#f5f8d5",
      "#eaefac",
      "#dfe67f",
      "#d6de5a",
      "#cfd941",
      "#ccd733",
      "#b4be25",
      "#9fa91b",
      "#88920a",
    ],
    persian: [
      "#ecfdfb",
      "#dbf9f4",
      "#b1f4e9",
      "#85efdd",
      "#65ead4",
      "#54e7cd",
      "#49e6ca",
      "#3bccb2",
      "#2eb69e",
      "#109d88",
    ],
  },
  breakpoints: {
    xs: "640px",
    sm: "768px",
    md: "1024px",
    lg: "1280px",
    xl: "1536px",
  },
};

export const theme = createTheme(themeObject);

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-body-background": theme.colors.neutral[0],
  },
  light: {
    "--mantine-body-background": theme.colors.neutral[0],
  },
  dark: {
    "--mantine-body-background": theme.colors.neutral[9],
  },
});
