// import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import MantineProviders from "./components/MantineProviders";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/charts/styles.css";

void (async () => {
  const HOTJAR_SITE_ID = import.meta.env.VITE_HOTJAR_SITE_ID;
  if (HOTJAR_SITE_ID) {
    const { initHotjar } = await import("./analytics/hotjar");
    initHotjar();
  }
  const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY;
  if (AMPLITUDE_API_KEY) {
    const { amplitudeInit } = await import("./analytics/amplitude");
    amplitudeInit();
  }
  const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
  if (SENTRY_DSN) {
    const { initSentry } = await import("./sentry");
    initSentry();
  }
})();

const rootEl = document.getElementById("root");
if (rootEl) {
  ReactDOM.createRoot(rootEl).render(
    // <React.StrictMode>
    <MantineProviders>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </MantineProviders>,
    // </React.StrictMode>,
  );
}
