import HttpClient from "@/http/httpClient";
import type {
  AreaBumpChartData,
  GetInventoryResponseType,
  GetRegulatoryInventoryData,
  GetRegulatorsResponseType,
  GetUpdatesResponseType,
  WelcomeBannerDataResponseType,
  RegHubFeedbackResponseType,
  RegHubFeedbackData,
  RegHubAdditionalFeedbackData,
  GetObligationsResponseType,
  GetControlsResponseType,
  PostControlResponseType,
  PutObligationRequestType,
  PutObligationResponseType,
  PutControlRequestType,
  PutControlResponseType,
  GetObligationByIdResponseType,
  GetControlByIdResponseType,
  GetRegulatoryActionsResponseType,
  PublicationStatusEnum,
  GetRegulatoryChatbotsResponseType,
  HorizonScanningVisualsDataResponse,
} from "./types";
import { baseHttp } from "@/http/baseClient";
import type {
  ControlDataType,
  ObligationStatusEnum,
} from "@/views/RegulatoryHub/types";
import { formatInventoryItemsQuery } from "@/helpers";

export async function getRegulators() {
  return await HttpClient.request().get<GetRegulatorsResponseType>(
    "regulatory-updates/regulators",
  );
}

export async function getRegulatoryInventoryData(
  request: GetRegulatoryInventoryData,
) {
  return HttpClient.request().get<GetInventoryResponseType>(
    `regulatory-updates/inventory?${formatInventoryItemsQuery(request)}`,
  );
}

export async function getRecentRegulatoryUpdates() {
  return await HttpClient.request().get<GetUpdatesResponseType>(
    "regulatory-updates/recent-updates",
  );
}

export async function getRecentlyAddedRegulatoryUpdates() {
  return await HttpClient.request().get<GetUpdatesResponseType>(
    "regulatory-updates/recently-added",
  );
}

export async function getRegulatoryHubNews() {
  return await HttpClient.request().get<GetUpdatesResponseType>(
    "regulatory-updates/news",
  );
}

export async function getFunnelChartData(dateAfter: string) {
  return await HttpClient.request().get<AreaBumpChartData>(
    `regulatory-updates/statistics-by-regulator?date_after=${dateAfter}`,
  );
}

export async function getWelcomeBannerData() {
  return await HttpClient.request().get<WelcomeBannerDataResponseType>(
    "regulatory-updates/welcome",
  );
}

export async function postFeedback(feedbackData: RegHubFeedbackData) {
  return await baseHttp.post<RegHubFeedbackResponseType>(
    "regulatory-updates/feedback",
    {
      json: feedbackData,
    },
  );
}

export async function putFeedback(
  additionalFeedbackData: RegHubAdditionalFeedbackData,
) {
  return await baseHttp.put<RegHubFeedbackResponseType>(
    "regulatory-updates/feedback",
    {
      json: additionalFeedbackData,
    },
  );
}

export async function getObligations() {
  return await HttpClient.request().get<GetObligationsResponseType>(
    "regulatory-updates/obligations",
  );
}

export async function getObligationById(id: string) {
  return await HttpClient.request().get<GetObligationByIdResponseType>(
    `regulatory-updates/obligations/${id}`,
  );
}

export async function getControls() {
  return await HttpClient.request().get<GetControlsResponseType>(
    "regulatory-updates/controls",
  );
}

export async function getControlById(id: string) {
  return await HttpClient.request().get<GetControlByIdResponseType>(
    `regulatory-updates/controls/${id}`,
  );
}

export async function postControl(controlData: Partial<ControlDataType>) {
  return HttpClient.request().post<PostControlResponseType>(
    "regulatory-updates/controls",
    {
      json: controlData,
    },
  );
}

export async function putObligation(
  id: string,
  obligation: PutObligationRequestType,
) {
  return HttpClient.request().put<PutObligationResponseType>(
    `regulatory-updates/obligations/${id}`,
    {
      json: obligation,
    },
  );
}

export async function putObligationBatchStatus(
  status: ObligationStatusEnum,
  obligationIds: string[],
) {
  return HttpClient.request().put<PutObligationResponseType>(
    `regulatory-updates/obligations/batch-status`,
    {
      json: {
        status,
        obligations_ids: obligationIds,
      },
    },
  );
}

export async function putControl(id: string, control: PutControlRequestType) {
  return HttpClient.request().put<PutControlResponseType>(
    `regulatory-updates/controls/${id}`,
    {
      json: control,
    },
  );
}

export async function deleteControl(id: string) {
  return HttpClient.request().delete<string>(
    `regulatory-updates/controls/${id}`,
  );
}

export async function getRegulatoryActionsData(id: string) {
  return HttpClient.request().get<GetRegulatoryActionsResponseType>(
    `regulatory-updates/${id}/actions`,
  );
}

export async function getRegulatoryChatbotsData(id: string) {
  return HttpClient.request().get<GetRegulatoryChatbotsResponseType>(
    `regulatory-updates/${id}/chatbots`,
  );
}

export async function updatePublicationStatus(
  id: string,
  status: PublicationStatusEnum,
) {
  return HttpClient.request().put<GetRegulatoryActionsResponseType>(
    `regulatory-updates/publication/${id}`,
    {
      json: { status },
    },
  );
}

export async function deriveObligations(id: string) {
  return HttpClient.request().put<GetRegulatoryActionsResponseType>(
    `regulatory-updates/publication/${id}`,
    {
      json: {
        obligations_derived: true,
      },
    },
  );
}

export async function getHorizonScanningStatistics(
  date_to: string,
  date_from: string,
) {
  return await HttpClient.request().get<HorizonScanningVisualsDataResponse>(
    `regulatory-updates/horizon-scanning-statistics?date_from=${date_from}&date_to=${date_to}`,
  );
}

export async function getBookmarks() {
  return HttpClient.request().get<GetInventoryResponseType>(
    `regulatory-updates/user-bookmarks`,
  );
}

export async function postBookmark(id: string) {
  return HttpClient.request().post<GetInventoryResponseType>(
    `regulatory-updates/${id}/bookmark`,
  );
}

export async function deleteBookmark(id: string) {
  return HttpClient.request().delete<GetInventoryResponseType>(
    `regulatory-updates/${id}/bookmark`,
  );
}
