import { getAdminAnalytics } from "@/http/api/admin";
import type { LoaderFunctionArgs } from "react-router-dom";
import { handleExportResponse } from "@/views/Admin/Surveys/loader";

export default async function adminAnalyticsLoader({
  request,
}: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const param = url.searchParams;
  const export_metric = param.get("export_metric");
  const requestParams = export_metric ? { export_metric } : undefined;
  const response = await getAdminAnalytics(requestParams, {
    signal: request.signal,
  });
  if (export_metric) {
    try {
      await handleExportResponse(response);
    } catch (e) {
      console.log(e);
    } finally {
      window.location.replace(window.location.pathname);
    }
    return null;
  }
  return response;
}
